<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('LABEL:AdminPictures') }}<span v-if="picturesData && picturesData.length > 0">({{trans("LABEL:LeftPictures")}}: {{ picturesCounter }} / {{trans("LABEL:LeftProfiles")}}: {{picturesData.length}})</span>
    </PageTitle>
    <Loader v-if="picturesDataLoading" class="h-050screen"/>
    <t-card v-else class="w-page-width">
      <template v-if="picturesData">
        <div class="flex flex-col gap-3">
          <!-- ID picture + Username-->
          <div v-if="picturesData && picturesData.pictures.length > 0">
            <div class="flex flex-row w-full gap-6">
              <div class="w-300 max-h-300">
                Placeholder for ID picture
              </div>
              <div
                @click="showProfileModal(picturesData.ownerUsername)"
                class="flex flex-row justify-start font-semibold hover:text-redAccentLight hover:opacity-70 cursor-pointer w-300 max-h-300"
              >
                {{ picturesData.ownerName }}
              </div>
              <div
                class="flex flex-row justify-start w-300 max-h-300"
              >
                {{ trans('LABEL:VeriffPicture')}}
              </div>
            </div>
          </div>
          <hr/>
          <!-- Pictures -->
          <div v-for="(picture, index) in picturesData.pictures" :key="picture.id">
            <div v-if="index > 0" class="pb-3">
              <hr/>
            </div>
            <div class="w-full flex flex-col lg:flex-row gap-6">
              <div
                v-if="picture"
                @click="openImageModal(true, picture)"
                class="relative flex w-full lg:w-300 h-300 justify-center group border border-grayedHR rounded"
              >
                <Picture
                  :src="picture.normalUrl"
                  :key="picture.id"
                  classes="w-300 h-300 rounded object-contain cursor-pointer filter transition
                    group-hover:brightness-50 group-hover:grayscale group-hover:contrast-200
                  "
                  alt="picture"
                />
                <div class="absolute hidden group-hover:block top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer">
                  <base-icon bigger-view :width="48" :height="48" name="zoom">
                  </base-icon>
                </div>
              </div>

              <div class="w-330">
                <div class="w-full flex flex-row gap-6">
                  <div class="flex flex-col gap-1">
                    <div
                      v-for="status in picturesStatusesPositive"
                      :key="status.slug"
                    >
                      <label class="flex flex-row items-center gap-2">
                        <t-radio :name="`picture_status_${status.id}_${picture.id}_${picturesData.ownerUsername}`" v-model="pictureStatuses[picture.id]" :value="status.id"/>
                        <span class="text-s cursor-pointer text-badgeSuccess">{{ trans(status.name) }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-col gap-1">
                    <div
                      v-for="status in picturesStatusesNegative"
                      :key="status.id"
                    >
                      <label class="flex flex-row items-center gap-2">
                        <t-radio :name="`picture_status_${status.id}_${picture.id}_${picturesData.ownerUsername}`" v-model="pictureStatuses[picture.id]" :value="status.id"/>
                        <span class="text-s cursor-pointer text-badgeError">{{ trans(status.name) }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-330">
                <div
                    v-for="veriffImage in picturesData.veriff_photos"
                    v-if="picturesData.veriff_photos"
                    @click="openImageModal(true, {url: veriffImage})"
                    class="relative flex w-full lg:w-300 h-300 justify-center group border border-grayedHR rounded"
                >
                  <Picture
                      :src="veriffImage"
                      classes="w-300 h-300 rounded object-contain cursor-pointer filter transition
                    group-hover:brightness-50 group-hover:grayscale group-hover:contrast-200
                  "
                      alt="picture"
                  />
                  <div class="absolute hidden group-hover:block top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer">
                    <base-icon bigger-view :width="48" :height="48" name="zoom">
                    </base-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="w-full flex flex-row gap-6">
            <div class="w-300">
            </div>
            <div class="w-676">
              <Button
                @clicked="updateStatusAction()"
                :loading="pictureUpdating"
                :disabled="editBtnBlocked"
              >
                {{ trans('BUTTON:SaveStatus') }}
              </Button>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        {{ trans('LABEL:NoPicturesToAccept') }}
      </template>

    </t-card>

    <ImageModal
      variant="imageAdmin"
      v-if="isShowImageModal"
      :show-modal="isShowImageModal"
      @clicked="openImageModal(false)"
      :img="openedImage"
    >
    </ImageModal>

    <Modal
      variant="profileAdmin"
      hide-footer
      v-if="isShowProfileModal"
      :show-modal="isShowProfileModal"
      @clicked="isShowProfileModal = false"
    >
      <template #content>
        <ProfileGuest :on-modal=true :username="profileUsername" key="profileAdminPicturesModal"/>
      </template>
    </Modal>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import ImageModal from "@/utils/modal/ImageModal";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import ProfileGuest from "@/views/content/profile/ProfileGuest";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AdminPictures",
  components: {
    AdminPageFrame,
    BaseIcon,
    Button,
    ImageModal,
    Loader,
    Modal,
    PageTitle,
    ProfileGuest,
  },
  data() {
    return {
      pictureStatus: null,
      isShowImageModal: false,
      isShowProfileModal: false,
      openedImage: null,
	    pictureStatuses: [],
	    channelName: 'pictures-approving.',
	    editBtnBlocked: false,
    }
  },
  watch: {
    picturesData: {
      deep: true,
      handler(val) {
        if(val) {
          this.pictureStatus = null;
	        let presenceChannel = window.Echo.join(`${this.channelName}${val.id}`);
	        setTimeout(() => {
		        this.editBtnBlocked = true;
		        this.leaveChannel(val.id);

	        }, Number(this.configData.presenceChannelsTimeout));
        }
      }
    }
  },
  computed: {
    ...mapGetters(
        'admin', ['picturesData', 'picturesStatusesPositive', 'picturesStatusesNegative', 'pictureUpdating', 'picturesCounter', 'picturesDataLoading']
    ),
	  ...mapGetters('lang', ['configData']),
  },
  methods: {
    ...mapActions('admin', ['fetchAdminPicturesData', 'updatePicturesStatus']),
	  ...mapMutations('admin', ['setAdminPicturesData']),

    updateStatusAction() {
			let data = [];
			this.pictureStatuses.filter((key, value) => {
				data.push({pictureId: value, pictureStatus: key});
			})

      this.updatePicturesStatus(data)
    },

    openImageModal(value, src = null) {
      this.isShowImageModal = value;
      this.openedImage = value ? src : null;
    },

    showProfileModal(username) {
      this.isShowProfileModal = true;
      this.profileUsername = username;
    },
	  leaveChannel(channelId) {
		  window.Echo.leave(`${this.channelName}${channelId}`);
		  this.setAdminPicturesData({pictures: null});
	  },

  },
	beforeDestroy() {
		window.Echo.leave(`${this.channelName}${this.picturesData?.id}`)
		this.setAdminPicturesData({pictures: null});
	},
	mounted() {
    this.fetchAdminPicturesData();
  }
}
</script>

